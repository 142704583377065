import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { getCompanyGlobalScore, getCompanyGlobalScoreLabel, getCompanyTotalReviews, getCompanyDrivingAddressLink } from '../utilities/companyHelpers'

import heroStyles from '../components/hero.module.css'
import { intersection } from "lodash"

class CategoryGroupTemplate extends React.Component {

  constructor(props) {
    super(props);

    const categoryGroup = get(this.props, 'data.contentfulCategoryGroup')    
    const myCompanies = this.getCompanies(categoryGroup);

    // Define the initial state:
    this.state = {
      filterByCategory : [],
      filterByRating : [],
      myCompanies : myCompanies
    };    
  }

  onFilterCategoryChange = event => {

    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    let filterByCategory = [...this.state.filterByCategory] || [];
      
    if(checked && filterByCategory.filter(x => x == name).length == 0)
      filterByCategory.push(name)
    else
      filterByCategory = filterByCategory.filter(x => x != name);

    this.setState({ filterByCategory : filterByCategory });
    this.applyFilter(filterByCategory, this.state.filterByRating);
  }

  onFilterRatingChange = event => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    let filterByRating = [...this.state.filterByRating] || [];
      
    if(checked && filterByRating.filter(x => x == name).length == 0)
      filterByRating.push(name)
    else
      filterByRating = filterByRating.filter(x => x != name);

    this.setState({ filterByRating : filterByRating });
    this.applyFilter(this.state.filterByCategory, filterByRating);
  }

  applyFilter(filterByCategory, filterByRating) {
    // 1. Make a shallow copy of the items
    let myCompanies = [...this.state.myCompanies];
    // 2. Make a shallow copy of the item you want to mutate

    myCompanies.forEach((companyToHide, index) => {       
      let myCompany = {...myCompanies[index]};
      myCompany.isVisible = this.isVisible(companyToHide, filterByCategory, filterByRating)
      myCompanies[index] = myCompany;
    });

    // 5. Set the state to our new copy
    this.setState({myCompanies});
  }

  isVisible(company, filterByCategory, filterByRating)
  {    
      // If there are no current categories selected, then don't filter by category
      if(filterByCategory.length == 0 && filterByRating.length == 0)
        return true; //

      // default values
      var foundInCategory = true;
      var foundInRating = true;

      // apply filter if set
      if(filterByCategory.length > 0) 
      {
        // override default value
        foundInCategory = filterByCategory.filter(categoryId => 
          this.companyIsInCategory(company, categoryId)).length > 0;
      }

      // apply filter if set
      if(filterByRating.length > 0) 
      {
        var ratingGlobal = getCompanyGlobalScore(company);
      
        // override default value
        foundInRating = filterByRating.filter(ratingLabel => 
        {
          if(ratingLabel == "_" && ratingGlobal == 0)
            return true;

          if(ratingLabel == "1-2" && ratingGlobal > 0 && ratingGlobal < 3)
            return true;
          
          return ratingGlobal >= ratingLabel;        
        }).length > 0;
      }
            
      return foundInCategory && foundInRating;
  }

  companyIsInCategory(company, categoryId)
  {
    if(company.categories.filter(companyCategory => companyCategory.id == categoryId).length == 1) 
    {
      return true;
    }

    return false;
  }

  countCompaniesInCategory(categoryId, companies)
  {
      if(!companies)
        return 0;

      var match = companies.filter(company => {
        if(!company.categories)
          return false;

        return company.categories.filter(category => category.id == categoryId).length > 0;
      })

      return match.length;
  }

  countCompaniesInScoreLabel(scoreLabel, companies)
  {
      if(!companies)
        return 0;

      var match = companies.filter(company => {        
        var scoreLabelOfCompany = getCompanyGlobalScoreLabel(company);
        return scoreLabel == scoreLabelOfCompany;
      })

      return match.length;
  }

  getCompanies(categoryGroup){
    var entries;

    var entries = categoryGroup.categories.map(category => { return category.company }).map(entry => entry && entry[0]);
    var entries = entries.filter(entry => entry != null);

    // remove duplicated companies
    if(!entries)
      return null;

    var uniqueEntries = [];

    entries.forEach((companyToAdd) => {
      // add a company if it doesn't exist already
      if (uniqueEntries.filter(existingCompany => existingCompany.id == companyToAdd.id).length == 0) {
        companyToAdd.isVisible = true;
        uniqueEntries.push(companyToAdd);
      }
    });

    return uniqueEntries;
  }

  render() {
    const categoryGroup = get(this.props, 'data.contentfulCategoryGroup')
    const siteTitle = get(this.props, 'data.siteMetaData.siteMetadata.title')
    const companies = this.getCompanies(categoryGroup);
    const categories = categoryGroup.categories;
    
    return (
      <Layout location={this.props.location}>
        
         <Helmet title={`${categoryGroup.name} | ${siteTitle}`} /> 

          <main>              
		<div id="results">
		   <div class="container">
			   <div class="row">
				   <div class="col-lg-3 col-md-4 col-10">
					   <h4><strong>{this.state.myCompanies && this.state.myCompanies.length}</strong> resultado(s)</h4>
				   </div> 

				   {/* <div class="col-lg-9 col-md-8 col-2">
					   <a href="#0" class="side_panel btn_search_mobile"></a> 
              <div class="row no-gutters custom-search-input-2 inner">
                <div class="col-lg-5">
                  <div class="form-group">
                    <input class="form-control" type="text" placeholder="What are you looking for..."/>
                    <i class="icon_search"></i>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <input class="form-control" type="text" placeholder="Where"/>
                    <i class="icon_pin_alt"></i>
                  </div>
                </div>
                <div class="col-lg-3">
                  <select class="wide">
                    <option>All Categories</option>	
                    <option>Shops</option>
                    <option>Hotels</option>
                    <option>Restaurants</option>
                    <option>Bars</option>
                    <option>Events</option>
                    <option>Fitness</option>
                  </select>
                </div>
                <div class="col-lg-1">
                  <input type="submit"/>
                </div>
              </div>
				   </div>*/}
          </div> 			   
		   </div>		  
      </div>
      
	   {/* 
		<div class="filters_listing version_2  sticky_horizontal">
			<div class="container">
				<ul class="clearfix">
					<li>
						<div class="switch-field">
							<input type="radio" id="all" name="listing_filter" value="all" checked/>
							<label for="all">All</label>
							<input type="radio" id="popular" name="listing_filter" value="popular"/>
							<label for="popular">Popular</label>
							<input type="radio" id="latest" name="listing_filter" value="latest"/>
							<label for="latest">Latest</label>
						</div>
					</li>
					<li>
						<div class="layout_view">
							<a href="#0" class="active"><i class="icon-th"></i></a>
							<a href="listing-2.html"><i class="icon-th-list"></i></a>
							<a href="list-map.html"><i class="icon-map"></i></a>
						</div>
					</li>
					<li>
						<a class="btn_map" data-toggle="collapse" href="#collapseMap" aria-expanded="false" aria-controls="collapseMap" data-text-swap="Hide map" data-text-original="View on map">View on map</a>
					</li>
				</ul>
			</div>
	
		</div>	*/}
		{/* /filters */}
		
		<div class="collapse" id="collapseMap">
			<div id="map" class="map"></div>
		</div>
		{/* /Map */}
		
		<div class="container margin_60_35">
			<div class="row">
				<aside class="col-lg-3" id="sidebar">
					<div id="filters_col">
						<a data-toggle="collapse" href="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters" id="filters_col_bt">Filtros </a>
						<div class="collapse show" id="collapseFilters">
							<div class="filter_type">
								<h6>Categorias</h6>
								<ul>
                  {categories.map(category => {
                    return (
                      <li key={category.id}>
                        <label class="container_check">{category.name} 
                          <small>{this.countCompaniesInCategory(category.id, companies)}</small>
                          <input 
                              name={category.id}
                              type="checkbox"                              
                              onChange={this.onFilterCategoryChange}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    )})}									
								</ul>
							</div>

              {/* 
              Filter by Distance
              <div class="filter_type">
                                <h6>Distance</h6>
                                <div class="distance"> Radius around selected destination <span></span> km</div>
								<input type="range" min="10" max="100" step="10" value="30" data-orientation="horizontal"/>
                    </div> */}

							<div class="filter_type">
								<h6>Rating</h6>
								<ul>
									<li>
										<label class="container_check">Muy Confiable! 5<small>
                      {this.countCompaniesInScoreLabel("Muy Confiable!", companies)}</small>
                      <input 
                              name="5"
                              type="checkbox"                              
                              onChange={this.onFilterRatingChange}
                          />
										  <span class="checkmark"></span>
										</label>
									</li>
									<li>
                  <label class="container_check">Muy Bueno 4+<small>
                      {this.countCompaniesInScoreLabel("Muy Bueno", companies)}</small>
                      <input 
                              name="4"
                              type="checkbox"                              
                              onChange={this.onFilterRatingChange}
                          />
										  <span class="checkmark"></span>
										</label>
									</li>
									<li>
									<label class="container_check">Bueno 3+<small>
                      {this.countCompaniesInScoreLabel("Bueno", companies)}</small>
                      <input 
                              name="3"
                              type="checkbox"                              
                              onChange={this.onFilterRatingChange}
                          />
										  <span class="checkmark"></span>
										</label>
									</li>
									<li>
                    <label class="container_check">No Bueno 1-2<small>
                        {this.countCompaniesInScoreLabel("Cuidado", companies)}</small>
                        <input 
                                name="1"
                                type="checkbox"                              
                                onChange={this.onFilterRatingChange}
                            />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  <li>
                  <label class="container_check">Desconocido ?<small>
                      {this.countCompaniesInScoreLabel("Sin Conocer", companies)}</small>
                      <input 
                              name="_"
                              type="checkbox"                              
                              onChange={this.onFilterRatingChange}
                          />
										  <span class="checkmark"></span>
										</label>
									</li>
								</ul>
							</div>
						</div>
						{/*/collapse */}
					</div>
					{/*/filters col*/}
				</aside>
				{/* /aside */}

				
				<div class="col-lg-9">
        {this.state.myCompanies && this.state.myCompanies.map(company => {
            return (                                            
            <div key={company.id}  class="strip list_view" 
                data-company={company.id}
                data-categories={company.categories.map(category => category.id).join()} style={{ display : company.isVisible ? "block" : "none"}}>              
                
              <div class="row no-gutters">
                <div class="col-lg-5">
                  <figure>
                    <Link to={ '/company/' + company.slug } >
                      {company.heroImage &&
                        <img src={company.heroImage.fixed.src} class="img-fluid" alt=""/>
                      }
                      <div class="read_more"><span>Ver mas</span></div>
                    </Link>
                    {/* {company.categories && company.categories.map(category => {
                      return <small>{category.name}</small>
                    })} */}
                  </figure>
                </div>
                <div class="col-lg-7">
                  <div class="wrapper">                   
                    {/* <a href="#0" class="wish_bt"></a> */}
                    <h3><Link to={ '/company/' + company.slug } >{company.companyName}</Link></h3>
                    {company.primaryAddress && 
                    <small>
                      {company.primaryAddress.streetAddress1} {company.primaryAddress.streetAddress2} {company.primaryAddress.city}, {company.primaryAddress.state} {company.primaryAddress.country}, {company.primaryAddress.zipCode}
                    </small>}
                    <p>{company.shortDescription}</p>

                    {company.categories && company.categories.map(category => {
                      return <span class="loc_open" style={{ marginRight : "5px" }}>{category.name}</span>
                    })}
                    {/* <a class="address" href={getCompanyDrivingAddressLink(company)}>Obtener dirección</a> */}
                  </div>
                  <ul>
                    {/* <li><span class="loc_open">Now Open</span></li> */}
                    <li><div class="score"><span>{getCompanyGlobalScoreLabel(company)}<em>{getCompanyTotalReviews(company)} Testimonios</em></span><strong>{getCompanyGlobalScore(company)}</strong></div></li>
                  </ul>
                </div>
              </div>
            </div>
            )
        })}
					
					{/* <p class="text-center"><a href="#0" class="btn_1 rounded add_top_30">Load more</a></p> */}
				</div>
			</div>		
		</div>

	</main>
               
       
      </Layout>
    )
  }
}

export default CategoryGroupTemplate

export const pageQuery = graphql`
  query CategoryGroupBySlug($slug: String!) {
    siteMetaData: site {
      siteMetadata {
        title
      }
    }
    contentfulCategoryGroup(slug: { eq: $slug }) {
      name      
      slug
      categories
      {
        id
        name
        categorySlug
        company {
          id
          companyName
          slug
          heroImage {            
            fixed(width: 350, height: 300, quality: 100) {                
                width
                height
                src
                srcSet
              }
          }
          primaryAddress {
            streetAddress1
            streetAddress2
            city
            state
            country
            zipCode
          }
          description {
            childMarkdownRemark {
              html 
            }
          },
          shortDescription
          reviews {
            id                    
            rating
          }
          categories {
            id
            name
            categorySlug
          }
        }
      }
    }
  }
`
