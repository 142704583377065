export function getCompanyGlobalScore(company)
{
    if(!company.reviews)
        return 0;
        
    var sum = company.reviews.slice().map(entry => entry.rating).reduce((a, b) => a + b, 0)
    return sum / company.reviews.length;
}

export function getCompanyGlobalScoreLabel(company)
{
    if(!company.reviews)
        return "Sin Conocer";
        
    var sum = company.reviews.slice().map(entry => entry.rating).reduce((a, b) => a + b, 0)
    var value = sum / company.reviews.length;

    if(value >= 5)
        return "Muy Confiable!";

    if(value >= 4)
        return "Muy Bueno";

    if(value >= 3)
        return "Bueno";

    if(value >= 0)
        return "Cuidado";
}

export function getCompanyTotalReviews(company)
{
    if(!company.reviews)
        return 0;
        
    return company.reviews.length;
}

export function getCompanyDrivingAddressLink(company){

    // How to build the Google Maps URL that will cause Google App to
    // open on the customer's browser...
    // https://developers.google.com/maps/documentation/urls/get-started

    
    var queryTerm = "";
    queryTerm += `${company.primaryAddress.streetAddress1}`;

    // street address 2 is optional
    if(company.primaryAddress.streetAddress2)
        queryTerm += `,${company.primaryAddress.streetAddress2}`;

    queryTerm += `,${company.primaryAddress.city} ${company.primaryAddress.state} ${company.primaryAddress.country}, ${company.primaryAddress.zipCode}`;

    var basePath = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(queryTerm)}`;

    return basePath;
}